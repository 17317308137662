:root {
  /* Colors */
  --bg-primary: #1F262E;
  --bg-secondary: #E35A01;
  --bg-tertiary: #4C7C71;
  
  /* Typography */
  --font-primary: 'Saira', sans-serif;
  --font-display: "Major Mono Display", monospace;
  --font-size-base: calc(10px + 2vmin);
  --font-size-large: calc(10px + 2vmin + 25%);
  --font-size-small: 14px;
  
  /* Spacing */
  --spacing-small: 10px;
  --spacing-medium: 20px;
  --spacing-large: 30px;
  
  /* Transitions */
  --transition-speed: 0.3s;
  
  /* Border Radius */
  --border-radius: 4px;
}

.App {
  text-align: center;
  background-color: var(--bg-primary);
}

.App-header {
  background-color: var(--bg-primary);
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: var(--font-size-base);
  font-family: var(--font-primary);
  color: white;
  padding: var(--spacing-medium);
}

.brand-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-large);
  font-family: var(--font-display);
}

.App-body {
  background-color: var(--bg-primary);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-base);
  font-family: var(--font-primary);
  padding: var(--spacing-medium);
}

.App-body-form {
  font-family: var(--font-primary);
  border-radius: var(--border-radius);
  padding: calc(var(--spacing-small) / 3);
  background-color: var(--bg-primary);
  margin: 0;
}

.App-body-form-button {
  color: white;
  padding: var(--spacing-small);
  margin-top: var(--spacing-small);
}

.App-body-form-button:hover {
  transition: 2s background ease;
  background-color: var(--bg-secondary);
}

.team-text {
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-medium);
  font-family: var(--font-primary);
  font-size: var(--font-size-small);
  color: white;
}

.team-text-main {
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
  color: white;
}

.App-link {
  color: #61dafb;
  text-decoration: none;
  font-size: calc(10px + 2vmin);
}

.FlexBox {
  height:100px;
  border: solid 3px #040e21ba;
  background-color: var(--bg-primary);
}

.Welcome {
  --bg-size: 400%;
  --color-one: var(--bg-tertiary);
  --color-two: var(--bg-primary);
  font-size: clamp(3rem, 25vmin, 8rem);
  background: linear-gradient(
                50deg,
                var(--color-one),
                var(--color-two),
                var(--color-one)
              ) 0 0 / 100% 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  padding: 0.1rem 0.2rem 0.1rem 1rem;
  font-family: var(--font-primary);
}

.Welcome-new {
  --bg-size: 400%;
  --color-one: var(--bg-tertiary);
  --color-two: var(--bg-primary);
  font-size: clamp(2.25rem, 18.75vmin, 6rem);
  background: linear-gradient(
                50deg,
                var(--color-one),
                var(--color-two),
                var(--color-one)
              ) 0 0 / 100% 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  padding: 0.1rem 0.2rem 0.1rem 1rem;
  font-family: var(--font-primary);
}

.Welcome-new-main {
  --bg-size: 400%;
  --color-one: var(--bg-tertiary);
  --color-two: var(--bg-secondary);
  font-size: clamp(0.84375rem, 7.03125vmin, 2.25rem);
  background: linear-gradient(
                50deg,
                var(--color-one),
                var(--color-two),
                var(--color-one)
              ) 0 0 / 100% 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  padding: 0.05rem 0.1rem 0.05rem 0.5rem;
  margin: 0;
  line-height: 1.0;
  font-family: var(--font-primary);
}

.branding-logo {
  margin: auto;
  width: 250px;
  height: 52px;
  border-radius: 80px;
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.center-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: calc(var(--spacing-small) * 0.8);
  margin: 5px auto -28px auto;
  padding: calc(var(--spacing-small) * 0.8);
  max-width: 90%;
  height: 30px;
  font-size: 13px;
}

.nav-button {
  padding: calc(var(--spacing-small) * 0.7) calc(var(--spacing-small) * 1.2);
  background-color: var(--bg-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  font-weight: 200;
  text-decoration: none;
  transition: background-color var(--transition-speed);
}

@media (max-width: 480px) {
  .center-nav {
    gap: calc(var(--spacing-small) * 0.5);
    padding: calc(var(--spacing-small) * 0.5);
  }
  
  .nav-button {
    padding: calc(var(--spacing-small) * 0.5) calc(var(--spacing-small) * 0.8);
  }
}

.nav-button:hover {
  background-color: var(--bg-secondary);
}

.terms-nav-button {
  padding: calc(var(--spacing-small) * 0.6) var(--spacing-small);
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-weight: 300;
  text-decoration: none;
  transition: opacity var(--transition-speed);
}

.terms-nav-button:hover {
  opacity: 0.7;
}

.Bottom {
  display: flex;
  background-color: var(--bg-primary);
  align-items: center;
  justify-content: center;
  padding-bottom: 1%;
  color: white;
  min-height: 8vh;
  font-size: var(--font-size-small);
  font-family: var(--font-primary);
}

.branding-logo {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.product-container {
  font-family: var(--font-primary);
  font-size: var(--font-size-small);
  color: white;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: var(--spacing-medium);
}

.sidebar {
  width: 20%;
  border-right: 1px solid #ccc;
  padding: var(--spacing-medium);
}

.category {
  padding: var(--spacing-small);
  margin: calc(var(--spacing-small) / 2) 0;
  cursor: pointer;
  transition: background-color var(--transition-speed) ease, color var(--transition-speed) ease;
}

.category:hover {
  background-color: var(--bg-secondary);
}

.category.active {
  background-color: var(--bg-secondary);
  font-weight: bold;
}

.product-display {
  width: 80%;
  padding: 10px;
}

.product-box {
  border: 1px solid #ccc;
  padding: var(--spacing-small); 
  margin: var(--spacing-small) 0;
}

.collection-item {
  color: #E9EAEC;
}

.collection-defs {
  color: #E9EAEC;
}

/* Cookie Disclosure Styles */
.cookie-disclosure {
  position: fixed;
  bottom: var(--spacing-medium);
  left: var(--spacing-medium);
  background-color: var(--bg-tertiary);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);
  padding: var(--spacing-small) var(--spacing-medium);
  z-index: 1000;
  max-width: 400px;
}

.cookie-disclosure-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  font-family: 'Saira', sans-serif;
  color: white;
}

.cookie-disclosure-content p {
  margin: 0;
  font-size: calc(var(--font-size-small) * 0.85);
}

.cookie-disclosure-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.got-it-btn {
  background-color: var(--bg-secondary);
  color: white;
  border: none;
  padding: calc(var(--spacing-small) * 0.8) var(--spacing-small);
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: var(--font-size-small);
  font-family: var(--font-primary);
  white-space: nowrap;
  min-width: 60px;
}

.got-it-btn:hover {
  background-color: var(--bg-secondary);
}

.close-btn {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  font-size: var(--font-size-small);
  padding: calc(var(--spacing-small) * 0.4) calc(var(--spacing-small) * 0.8);
}

.close-btn:hover {
  color: rgba(255, 255, 255, 0.8);
}
